import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

const NavAlumnos = () => {
  const navigate = useNavigate();
  const [username] = useState(sessionStorage.getItem("userName"));
  const [alumnoId, setAlumnoId] = useState("");
  const name = sessionStorage.getItem("userName");

  useEffect(() => {
    const storedAlumnoId = sessionStorage.getItem("alumnoId");
    if (storedAlumnoId) {
      setAlumnoId(storedAlumnoId);
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
    window.location.reload();
  };

  return (
    <>
      <nav className="navbar navbar-expand-xl nav-category z-index-9">
        <div className="container-sm">
0
          <a className="navbar-brand" href="/">
            <img
              className="light-mode-item navbar-brand-item"
              src="/assets/images/logo.png"
              alt="logo"
              style={{ width: "150px", height: "auto" }}
            />
          </a>
          {/* Logo END */}

          {/* Responsive navbar toggler */}
          <button
            className="navbar-toggler ms-auto btn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-animation">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          {/* Main navbar START */}
          <div className="navbar-collapse collapse" id="navbarCollapse">
            {/* Nav Search START */}
            <div className="col-xl-6">
              <div className="nav my-3 my-xl-0 px-4 flex-nowrap align-items-center">
                <div className="nav-item w-100">
                  {/* <form className="rounded position-relative">
                      <input
                        className="form-control pe-5 bg-secondary bg-opacity-10 border-0"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <button
                        className="btn btn-link bg-transparent px-2 py-0 position-absolute top-50 end-0 translate-middle-y"
                        type="submit"
                      >
                        <i className="fas fa-search fs-6 text-primary"></i>
                      </button>
                    </form> */}
                </div>
              </div>
            </div>
            {/* Nav Search END */}

            {/* Nav Main menu START */}
            <ul className="navbar-nav navbar-nav-scroll ms-auto">
              <li className="nav-item dropdown">
                {alumnoId && (
                  <Link
                    to={`/AulaVirtaul/${alumnoId}`}
                    className="nav-link"
                    style={{ color: "#009688" }}
                  >
                    Aula virtual
                  </Link>
                )}
              </li>
            </ul>
          </div>

          <ul className="nav flex-row list-unstyled ms-xl-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                data-bs-toggle="dropdown"
              >
                <i className="align-middle" data-feather="settings"></i>
              </a>
              <a
                className="nav-link dropdown-toggle d-none d-sm-inline-block"
                data-bs-toggle="dropdown"
              >
                <img
                  src="/img/avatars/avatar.webp"
                  className="avatar img-fluid rounded-circle "
                  alt={`Imagen de ${name}`}
                  style={{
                    minWidth: "2.3rem",
                    minHeight: "2.3rem",
                    objectFit: "cover",
                    maxWidth: "2.3rem",
                    maxHeight: "2.3rem",
                  }}
                />
                <span className="mx-1">{name}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  className="dropdown-item"
                  to={`/editar-contraeña/${alumnoId}`}
                >
                  <i className="align-middle me-1" data-feather="user"></i>{" "}
                  Editar contraseña
                </Link>

                <span className="dropdown-item" onClick={handleLogout}>
                  Cerrar sesión
                </span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default NavAlumnos;
